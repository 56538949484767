/* eslint-disable new-cap */
import { useMediaQuery } from '@mui/material';
import { CSSProperties, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { usePerpetualOpenedPositions } from 'entities/Perpetual/lib/hooks';

import { useAccount } from 'shared/hooks';
import { logger } from 'shared/lib/logger';

import { ChartSpinner } from '../ChartSpinner';
import useTVDatafeed from '../tradingview/useTVDatafeed';
import { mapOffsetToTimezone, parseResolution } from '../tradingview/utils';
import { TokenPrices } from '../types';

import { SaveLoadAdapter } from './SaveLoadAdapter';
import { SettingsAdapter } from './SettingsAdapter';
import {
  SUPPORTED_RESOLUTIONS,
  TV_SAVE_LOAD_CHARTS_KEY,
  colors,
  defaultChartProps,
  disabledFeaturesOnMobile,
} from './constants';

export function getObjectKeyFromValue(value: any, object: any) {
  return Object.keys(object).find((key) => object[key] === value);
}

export function getMidPrice(prices: TokenPrices) {
  return prices.minPrice.plus(prices.maxPrice).div(2);
}

export type ChartLine = {
  price: number;
  title: string;
};

enum TranslationLegendKey {
  CLOSE = 4,
  HIGH = 2,
  LOW = 3,
  OPEN = 1,
}

type Props = {
  chartLines: ChartLine[];
  isLoading: boolean;
  isShort: boolean;
  onSelectToken: (token: string) => void;
  period: string;
  savedShouldShowPositionLines: boolean;
  setPeriod: (period: string) => void;
  symbol: string | undefined;
};

export default function TVChartContainer({
  symbol,
  savedShouldShowPositionLines,
  chartLines,
  isLoading,
  isShort,
  onSelectToken,
  period,
  setPeriod,
}: Props) {
  const { t, i18n } = useTranslation();

  const { isConnected } = useAccount();

  const { data: marksData } = usePerpetualOpenedPositions();
  const [marks, setMarksEntities] = useState<Array<TradingView.IExecutionLineAdapter | null | undefined>>([]);

  const [isVisible, setVisible] = useState(true);
  const chartContainerRef = useRef<HTMLDivElement | null>(null);
  const tvWidgetRef = useRef<TradingView.IChartingLibraryWidget | null>(null);
  const [chartReady, setChartReady] = useState(false);
  const [chartDataLoading, setChartDataLoading] = useState(true);
  const isMobile = useMediaQuery('(max-width: 767px)');
  const symbolRef = useRef(symbol);
  const [isSymbolLoaded, setIsSymbolLoaded] = useState(false);
  const legendIndexRef = useRef(TranslationLegendKey.OPEN);
  const { datafeed } = useTVDatafeed();

  const getChartSafely = useCallback(() => {
    try {
      return tvWidgetRef.current?.activeChart();
    } catch (e: any) {
      return null;
    }
  }, [tvWidgetRef.current]);

  const drawLineOnChart = useCallback(
    (title: string, price: number) => {
      if (chartReady && tvWidgetRef.current?.activeChart?.().dataReady(() => {})) {
        const chart = getChartSafely()!;
        const positionLine = chart.createPositionLine({ disableUndo: true });

        return positionLine
          .setText(title)
          .setPrice(price)
          .setQuantity('')
          .setLineStyle(1)
          .setLineLength(1)
          .setBodyFont(`normal 12pt "Relative", sans-serif`)
          .setBodyTextColor('#fff')
          .setLineColor('#3a3e5e')
          .setBodyBackgroundColor('#3a3e5e')
          .setBodyBorderColor('#3a3e5e');
      }
    },
    [chartReady],
  );

  const drawMarksOnChart = useCallback(() => {
    const chart = getChartSafely();

    if (!chart) return;

    marks.forEach((entity) => entity && entity.remove());

    if (!marksData || !isConnected) {
      setMarksEntities([]);
      return;
    }

    const positionsMap: Record<number, { long: number; short: number }> = {};

    const newMarks = marksData
      .filter((position) => position.baseTicker === chart.symbol().toLowerCase())
      .flatMap((mark) => {
        const entities: (TradingView.IExecutionLineAdapter | null | undefined)[] = [];

        const position = Math.floor(
          ((chart.getVisibleRange().to || Date.now()) - Math.round(Date.parse(mark.createdAt) / 1000)) /
            parseResolution(tvWidgetRef.current?.symbolInterval().interval || '1'),
        );
        const counter = positionsMap[position] || { short: 0, long: 0 };

        if (mark.isShort) {
          if (counter.short >= 1) return entities;
          counter.short += 1;
        } else {
          if (counter.long >= 1) return entities;
          counter.long += 1;
        }
        positionsMap[position] = counter;

        try {
          const entity = chart
            .createExecutionShape()
            .setText(mark.isShort ? 'S' : 'B')
            .setTextColor(mark.isShort ? '#EF4A4A' : '#00A556')
            .setArrowColor(mark.isShort ? '#EF4A4A' : '#00A556')
            .setDirection(mark.isShort ? 'sell' : 'buy')
            .setTime(Date.parse(mark.createdAt) / 1000)
            .setArrowHeight(18)
            .setArrowSpacing(10)
            .setPrice(+mark.initialPrice);

          entities.push(entity);
        } catch (e: any) {
          logger.error(e);
        }

        return entities;
      });

    setMarksEntities(newMarks);
  }, [marks, marksData, isConnected, tvWidgetRef, setMarksEntities]);

  useEffect(() => {
    if (!isSymbolLoaded && symbol) {
      symbolRef.current = symbol;
      setIsSymbolLoaded(true);
    }
  }, [symbol]);

  useEffect(() => {
    legendIndexRef.current = TranslationLegendKey.OPEN;
  }, [i18n.language]);

  useEffect(() => {
    const handleChange = (e: Event) => {
      if (e.type === 'pageshow') {
        setVisible(true);
      } else {
        setVisible(false);
      }
    };

    window.addEventListener('pageshow', handleChange);
    window.addEventListener('pagehide', handleChange);

    return () => {
      window.removeEventListener('pageshow', handleChange);
      window.removeEventListener('pagehide', handleChange);
    };
  }, []);

  useEffect(() => {
    const lines: (TradingView.IPositionLineAdapter | undefined)[] = [];
    if (savedShouldShowPositionLines) {
      chartLines.forEach((order) => {
        lines.push(drawLineOnChart(order.title, order.price));
      });
    }
    return () => {
      lines.forEach((line) => line?.remove());
    };
  }, [chartLines, savedShouldShowPositionLines, drawLineOnChart]);

  useEffect(() => {
    if (chartReady && tvWidgetRef.current && symbol && symbol !== tvWidgetRef.current?.activeChart?.().symbol()) {
      tvWidgetRef.current.setSymbol(symbol, getChartSafely()!.resolution(), () => null);
    }
  }, [symbol, isShort, chartReady, period]);

  useEffect(() => {
    if (chartReady && tvWidgetRef.current) {
      getChartSafely()?.dataReady(drawMarksOnChart);
    }
  }, [chartReady, tvWidgetRef.current, marksData, isConnected]);

  useEffect(() => {
    if (chartReady && tvWidgetRef.current) {
      getChartSafely()?.onDataLoaded().subscribe(null, drawMarksOnChart, true);
    }

    return () => {
      if (chartReady && tvWidgetRef.current) {
        getChartSafely()?.onDataLoaded().unsubscribe(null, drawMarksOnChart);
      }
    };
  }, [
    symbol,
    isShort,
    period,
    chartReady,
    marksData,
    isConnected,
    tvWidgetRef.current,
    drawMarksOnChart,
    getChartSafely,
  ]);

  useEffect(() => {
    const widgetOptions: any = {
      ...defaultChartProps,
      symbol: symbolRef.current, // Using ref to avoid unnecessary re-renders on symbol change and still have access to the latest symbol
      datafeed,
      container: chartContainerRef.current,
      disabled_features: isMobile
        ? defaultChartProps.disabled_features.concat(disabledFeaturesOnMobile)
        : defaultChartProps.disabled_features,
      custom_translate_function: (_: string, options: any) => {
        if (legendIndexRef.current === TranslationLegendKey.OPEN && options.context === 'in_legend') {
          legendIndexRef.current++;
          return t('perpetuals.chart.open');
        }
        if (legendIndexRef.current === TranslationLegendKey.HIGH && options.context === 'in_legend') {
          legendIndexRef.current++;
          return t('perpetuals.chart.high');
        }
        if (legendIndexRef.current === TranslationLegendKey.LOW && options.context === 'in_legend') {
          legendIndexRef.current++;
          return t('perpetuals.chart.low');
        }
        if (legendIndexRef.current === TranslationLegendKey.CLOSE && options.context === 'in_legend') {
          legendIndexRef.current++;
          return t('perpetuals.chart.close');
        }
        return null;
      },
      locale: i18n.language,
      interval: getObjectKeyFromValue(period, SUPPORTED_RESOLUTIONS),
      timezone: mapOffsetToTimezone((-new Date().getTimezoneOffset()).toString()),
      favorites: isMobile
        ? { intervals: [] }
        : { ...defaultChartProps.favorites, intervals: Object.keys(SUPPORTED_RESOLUTIONS) },
      custom_formatters: {
        timeFormatter: {
          format: (date: Date) => {
            const formatString = '%h:%m';
            return formatString
              .replace('%h', date.getUTCHours().toString().padStart(2, '0'))
              .replace('%m', date.getUTCMinutes().toString().padStart(2, '0'));
          },
        },
      },
      settings_adapter: new SettingsAdapter(),
      save_load_adapter: new SaveLoadAdapter(
        (JSON.parse(localStorage.getItem(TV_SAVE_LOAD_CHARTS_KEY) || '0') || []) as TradingView.ChartData[],
        (a: TradingView.ChartData[]) => {
          localStorage.setItem(TV_SAVE_LOAD_CHARTS_KEY, JSON.stringify(a));
        },
        onSelectToken,
      ),
    };
    if (window.TradingView && symbolRef.current) {
      tvWidgetRef.current = new window.TradingView.widget(widgetOptions);
      tvWidgetRef.current!.onChartReady(() => {
        setChartReady(true);
        tvWidgetRef.current!.applyOverrides({
          'paneProperties.background': colors.background,
          'paneProperties.backgroundType': 'solid',
        });
        tvWidgetRef.current
          ?.activeChart()
          .onIntervalChanged()
          .subscribe(null, (interval) => {
            if (SUPPORTED_RESOLUTIONS[interval]) {
              const period = SUPPORTED_RESOLUTIONS[+interval];
              setPeriod(period);
            }
          });

        tvWidgetRef.current?.activeChart().dataReady(() => {
          setChartDataLoading(false);
        });
      });
    }

    return () => {
      if (tvWidgetRef.current) {
        tvWidgetRef.current.remove();
        tvWidgetRef.current = null;
        setMarksEntities([]);
        setChartReady(false);
        setChartDataLoading(true);
      }
    };
    // We don't want to re-initialize the chart when the symbol changes. This will make the chart flicker.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.TradingView, isMobile, isVisible, i18n.language, isSymbolLoaded]);

  const style = useMemo<CSSProperties>(
    () => ({
      visibility: !(chartDataLoading || isLoading) ? 'visible' : 'hidden',
    }),
    [chartDataLoading, isLoading],
  );

  return (
    <div className="perpetuals-chart-content">
      {(chartDataLoading || isLoading) && <ChartSpinner />}
      <div style={style} ref={chartContainerRef} className="perpetuals-chart-tv" />
    </div>
  );
}
