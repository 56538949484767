import { useGetReferralOverviewQuery } from 'entities/Referral';

export const useReferralCodeLinkLoader = () => {
  const { error, isLoading } = useGetReferralOverviewQuery();

  return {
    isLoading: isLoading && !error,
  };
};

export const useReferralCodeLink = () => {
  const { data: referralCode, isError, isFetching, refetch } = useGetReferralOverviewQuery();
  const code = referralCode?.code ?? '';

  return {
    code,
    isError,
    isFetching,
    link: code ? `reholdio.com/j/${code}` : '',
    refetch: () => refetch({}),
  };
};
