import { Link } from '@mui/material';
import React, { useEffect } from 'react';

import { CloseIcon } from 'shared/assets';
import { useIsMobile } from 'shared/hooks';
import { Box } from 'shared/ui';

import styles from './Banner.module.scss';

type Props = {
  isOpen: boolean;
  setOpen: (value: boolean) => void;
};

export const Banner: React.FC<Props> = ({ isOpen, setOpen }) => {
  const isMobile = useIsMobile();

  useEffect(() => {
    if (isOpen) {
      document.body.style.paddingTop = isMobile ? '100px' : '85px';
    } else {
      document.body.style.paddingTop = '0px';
    }

    return () => {
      document.body.style.paddingTop = '0px';
    };
  }, [isMobile, isOpen]);

  return (
    <Box className={`${styles.banner} ${isOpen ? styles.open : styles.closed}`} backgroundColor="secondary-03">
      <Box height="100%" flexDirection="column" alignItems="center" justifyContent="center">
        <Link
          style={{ color: 'var(--white-01)', textDecorationColor: 'var(--white-01)' }}
          href="https://t.me/rehold_io/819"
          rel="nofollow"
          target="_blank"
        >
          DO NOT USE THE PROTOCOL. Renat Gafarov, CEO of ReHold, has withdrawn the remaining clients' funds including employees' salaries a total of $190,000.  Police in Russia are working on this criminal case, where he lives.
        </Link>
      </Box>
      <Box position="absolute" right={isMobile ? 12 : 24} top={isMobile ? 38 : 30.5} onClick={() => setOpen(false)}>
        <CloseIcon width={24} height={24} color="white" />
      </Box>
    </Box>
  );
};
