import { arbitrum } from 'shared/config/chains/arbitrum';
import { avalanche } from 'shared/config/chains/avalanche';

import { formatTVDate, formatTVTime } from './dates';

export const SUPPORTED_RESOLUTIONS: Record<number | string, string> = {
  1: '1m',
  5: '5m',
  15: '15m',
  60: '1h',
  240: '4h',
  '1D': '1d',
};

export const DEFAULT_SYMBOL_INFO = {
  description: '',
  exchange: '',
  supported_resolutions: Object.keys(SUPPORTED_RESOLUTIONS) as any,
  intraday_multipliers: ['1', '2', '5', '15', '30', '60', '120', '240', '360', '720'],
  daily_multipliers: ['1'],
  has_daily: true,
  has_intraday: true,
  minmov: 1,
  session: '24x7',
  type: 'crypto',
  listed_exchange: '',
  format: 'price' as any,
};

export const colors = {
  background: '#0B0915',
  'primary-01': '#05F283',
  'secondary-02': '#20123F',
  'secondary-03-disabled': '#3C2870',
};

export const TV_SAVE_LOAD_CHARTS_KEY = 'tv-save-load-charts';

const RED = '#FF5353';
const GREEN = '#00B876';
export const DEFAULT_PERIOD = '1m';

const chartStyleOverrides = ['candleStyle', 'hollowCandleStyle', 'haStyle'].reduce((acc, cv) => {
  acc[`mainSeriesProperties.${cv}.drawWick`] = true;
  acc[`mainSeriesProperties.${cv}.drawBorder`] = false;
  acc[`mainSeriesProperties.${cv}.upColor`] = GREEN;
  acc[`mainSeriesProperties.${cv}.downColor`] = RED;
  acc[`mainSeriesProperties.${cv}.wickUpColor`] = GREEN;
  acc[`mainSeriesProperties.${cv}.wickDownColor`] = RED;
  acc[`mainSeriesProperties.${cv}.borderUpColor`] = GREEN;
  acc[`mainSeriesProperties.${cv}.borderDownColor`] = RED;
  return acc;
}, {} as any);

const chartOverrides = {
  'paneProperties.legendProperties.showSeriesTitle': false,
  'paneProperties.background': colors.background,
  'paneProperties.backgroundGradientStartColor': colors.background,
  'paneProperties.backgroundGradientEndColor': colors.background,
  'paneProperties.backgroundType': 'solid',
  'paneProperties.separatorColor': colors['secondary-02'],
  'paneProperties.vertGridProperties.color': colors['secondary-02'],
  'paneProperties.vertGridProperties.style': 2,
  'paneProperties.horzGridProperties.color': colors['secondary-02'],
  'paneProperties.horzGridProperties.style': 2,
  'mainSeriesProperties.priceLineColor': colors['primary-01'],
  'scalesProperties.textColor': '#FFFFFF',
  'scalesProperties.lineColor': colors.background,
  ...chartStyleOverrides,
};

export const disabledFeaturesOnMobile = ['header_saveload', 'header_fullscreen_button'];

const disabledFeatures = [
  'volume_force_overlay',
  'show_logo_on_all_charts',
  'caption_buttons_text_if_possible',
  'create_volume_indicator_by_default',
  'header_compare',
  'compare_symbol',
  'display_market_status',
  'header_interval_dialog_button',
  'show_interval_dialog_on_key_press',
  'header_symbol_search',
  'popup_hints',
  'side_toolbar_in_fullscreen_mode',
  'header_in_fullscreen_mode',
  'header_fullscreen_button',
  'right_bar_stays_on_scroll',
  'symbol_info',
  'header_undo_redo',
];
const enabledFeatures = [
  'iframe_loading_compatibility_mode',
  'hide_resolution_in_legend',
  'items_favoriting',
  'hide_left_toolbar_by_default',
];

export const defaultChartProps = {
  theme: 'Dark',
  locale: 'en',
  library_path: '/charting_library/',
  clientId: 'tradingview.com',
  userId: 'public_user_id',
  fullscreen: false,
  autosize: true,
  header_widget_dom_node: false,
  overrides: chartOverrides,
  enabled_features: enabledFeatures,
  disabled_features: disabledFeatures,
  custom_css_url: '/tradingview-chart.css',
  loading_screen: {
    backgroundColor: '#000000',
    foregroundColor: '#2C2C2C',
  },
  favorites: {},
  custom_formatters: {
    timeFormatter: {
      format: (date: Date) => formatTVTime(date),
    },
    dateFormatter: {
      format: (date: Date) => formatTVDate(date),
    },
  },
};

export const availableNetworksForChart = [arbitrum.id, avalanche.id];
