import { observer } from 'mobx-react-lite';
import React, { useMemo, CFC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { useTour } from 'features/OnboardingTour';

import { useGetDualAssetsQuery } from 'entities/Dual';
import { useDualTariffByChain } from 'entities/Dual/lib/hooks/useDualTariffByChain';
import { useGetDualTokenAddress } from 'entities/Token/model/useGetDualTokenAddress';
import { useGetDualTokensAllQuery } from 'entities/Token/model/useGetDualTokensAllQuery';

import { CHAIN_INFO } from 'shared/config/chains/index';
import { useAccount } from 'shared/hooks';
import { useNetwork } from 'shared/hooks/network';
import { Box, Button, Text, Spinner, PageContent } from 'shared/ui';

import { DualCreateForm } from './ui/DualCreateForm';

type Props = { onNotFound?: (found: boolean) => void };

export const DualWidget: CFC<Props> = observer(({ onNotFound }) => {
  const { t } = useTranslation();
  const { network } = useNetwork();
  const { tour } = useTour();
  const { isConnected } = useAccount();

  const assetsState = useGetDualAssetsQuery();
  const tokensState = useGetDualTokensAllQuery();

  const { chainId, baseTicker, quoteTicker } = useParams();

  const assetId = baseTicker && quoteTicker ? `${baseTicker}/${quoteTicker}` : null;

  const clientHeight = document.getElementById('root')!.clientHeight;

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'instant' });
  }, [clientHeight]);

  const preparationView = useMemo(() => {
    const { data: assetsData, isLoading: isLoadingAssets, isError: isAssetsFailed } = assetsState;

    const {
      data: tokensData,
      isError: isTokensFailed,
      isFetching: isFetchingTokens,
      isLoading: isTokensLoading,
      refetch: refetchTokens,
    } = tokensState;

    const baseToken = useGetDualTokenAddress(baseTicker!, +chainId!);
    const quoteToken = useGetDualTokenAddress(quoteTicker!, +chainId!);
    const asset = assetsData?.find(
      (asset) => asset.baseToken === baseToken && asset.quoteToken === quoteToken && asset.chainId === +chainId!,
    );

    const { isLoading: isTariffLoading } = useDualTariffByChain({
      baseToken: (baseToken as string) || '',
      quoteToken: (quoteToken as string) || '',
      chainId: +chainId!,
    });

    const isLoading =
      !assetsData ||
      !tokensData ||
      (isTariffLoading && baseToken && quoteToken && !!chainId) ||
      isLoadingAssets ||
      isTokensLoading ||
      (isFetchingTokens && isTokensFailed);

    const isComingSoon = !CHAIN_INFO[network?.id];

    const isNotFound = !chainId || !baseTicker || !quoteTicker || !baseToken || !quoteToken || !asset;
    const isFailed = !assetId || isAssetsFailed || isTokensFailed;

    const handleRefetch = () => {
      if (isTokensFailed) {
        refetchTokens();
      }
    };

    if (isComingSoon) {
      return (
        <Text text="app-22-medium" color="primary-01">
          {t('common.soonOn')} {network?.name}
        </Text>
      );
    }

    if (isLoading) {
      return <Spinner />;
    }

    if (isNotFound) {
      onNotFound?.(true);
    }

    if (isFailed) {
      return (
        <>
          <Button onClick={handleRefetch} data-id="retry-dual-create">
            {t('common.fetchRetry')}
          </Button>
        </>
      );
    }
  }, [tokensState, network, t, assetsState]);

  useEffect(() => {
    if (tour?.isActive() && preparationView) tour?.hide();
    if (tour?.isActive() && !preparationView) tour?.show(`step-${tour?.getCurrentStep()?.id}`);
  }, [preparationView]);

  return (
    <PageContent
      px={{ default: 12, desktop: 16, tablet: 16 }}
      pt={{ desktop: 8, phone: 0, tablet: 4 }}
      pb={{ desktop: 20, phone: 0, tablet: 16 }}
    >
      {preparationView && (
        <Box alignItems="center" justifyContent="center" height={isConnected ? 620 : 600}>
          {preparationView}
        </Box>
      )}
      {!preparationView && <DualCreateForm />}
    </PageContent>
  );
});
